import {createEnv} from "@t3-oss/env-nextjs";
import {z} from "zod";

import dotenv from "dotenv";

dotenv.config(); // Load environment variables from .env file

export const ORMEnum = z.enum(["drizzle", "prisma", "supabase"]);
export const AuthTypeEnum = z.enum(["supabase", "nextauth"]);
export const EmailProviderEnum = z.enum(["mailgun", "ses", "sendgrid", "resend", "brevo"]);

export const Env = createEnv({
	// TODO fix the format of the URL and ID
	server: {
		DATABASE_URL: z.string().url().optional(),
		GOOGLE_CLIENT_ID: z.string().min(1).optional(),
		GOOGLE_CLIENT_SECRET: z.string().min(1).optional(),
		NEXTAUTH_SECRET: z.string().min(1).optional(),
		ANALYZE: z.string().default("false").optional(),
		MAILGUN_USERNAME: z.string().min(1).optional(),
		MAILGUN_PASSWORD: z.string().min(1).optional(),
		MAILGUN_API_KEY: z.string().min(1).optional(),
		MAILGUN_WAITLIST_ADDRESS: z.string().min(1).optional(),
		MAGIC_LINK_EMAIL_SERVER_USER: z.string().min(1).optional(),
		MAGIC_LINK_EMAIL_SERVER_PASSWORD: z.string().min(1).optional(),
		MAGIC_LINK_EMAIL_SERVER_HOST: z.string().min(1).optional(),
		MAGIC_LINK_EMAIL_SERVER_PORT: z.string().min(1).optional(),
		MAGIC_LINK_EMAIL_FROM: z.string().min(1).optional(),
		STRIPE_SECRET_KEY: z.string().min(1).optional(),
		HOST_URL: z.string().min(1).optional(),
		STRIPE_ENDPOINT_SECRET: z.string().min(1).optional(),
		OPEN_AI_API_KEY: z.string().min(1).optional(),
		WAITLIST_EMAIL_PROVIDER: EmailProviderEnum.optional(),
		RESEND_API_KEY: z.string().min(1).optional(),
		AWS_ACCESS_KEY_ID: z.string().min(1).optional(),
		AWS_SECRET_ACCESS_KEY: z.string().min(1).optional(),
		AWS_REGION: z.string().min(1).optional(),
		MEDIA_BUCKET_NAME: z.string().min(1).optional(),
		MEDIA_BUCKET_URL: z.string().min(1).optional(),
		RESUME_MEDIA_BUCKET_NAME: z.string().min(1).optional(),
		ORM_TYPE: ORMEnum.optional().default("prisma"),
		BREVO_API_KEY: z.string().min(1).optional(),
		BREVO_SUBSCRIPTION_LIST_ID: z.string().min(1).optional(),
		PLAN_1_DISCOUNT_CODE: z.string().min(1).optional(),
		PLAN_1_PRICE_ID: z.string().min(1).optional(),
		AUTH_TYPE: AuthTypeEnum.optional().default("nextauth"),
		GPT_MESSAGE_QUEUE_URL: z.string().min(1).optional(),
		INDEX_MESSAGE_QUEUE_URL: z.string().min(1).optional(),
		MONGO_DB_LOCAL_SEARCH: z.string().default("true").optional(),
		SITE_URL: z.string().url().optional(),
	},
	client: {
		NEXT_PUBLIC_CRISP_ID: z.string().min(1).optional(),
		NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: z.string().min(1).optional(),
		NEXT_PUBLIC_SITE_URL: z.string().url().optional(),
		NEXT_PUBLIC_MAIN_URL: z.string().url().optional(),
		NEXT_PUBLIC_POSTHOG_KEY: z.string().optional(),
		NEXT_PUBLIC_POSTHOG_HOST: z.string().url().optional()
	},
	runtimeEnv: {
		DATABASE_URL: process.env.DATABASE_URL,
		GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
		GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
		ANALYZE: process.env.ANALYZE,
		NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
		MAILGUN_USERNAME: process.env.MAILGUN_USERNAME,
		MAILGUN_PASSWORD: process.env.MAILGUN_PASSWORD,
		MAILGUN_API_KEY: process.env.MAILGUN_API_KEY,
		MAILGUN_WAITLIST_ADDRESS: process.env.MAILGUN_WAITLIST_ADDRESS,
		MAGIC_LINK_EMAIL_SERVER_USER: process.env.MAGIC_LINK_EMAIL_SERVER_USER,
		MAGIC_LINK_EMAIL_SERVER_PASSWORD: process.env.MAGIC_LINK_EMAIL_SERVER_PASSWORD,
		MAGIC_LINK_EMAIL_SERVER_HOST: process.env.MAGIC_LINK_EMAIL_SERVER_HOST,
		MAGIC_LINK_EMAIL_SERVER_PORT: process.env.MAGIC_LINK_EMAIL_SERVER_PORT,
		MAGIC_LINK_EMAIL_FROM: process.env.MAGIC_LINK_EMAIL_FROM,
		NEXT_PUBLIC_CRISP_ID: process.env.NEXT_PUBLIC_CRISP_ID,
		NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
		NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
		STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
		HOST_URL: process.env.HOST_URL,
		STRIPE_ENDPOINT_SECRET: process.env.STRIPE_ENDPOINT_SECRET,
		OPEN_AI_API_KEY: process.env.OPEN_AI_API_KEY,
		WAITLIST_EMAIL_PROVIDER: process.env.WAITLIST_EMAIL_PROVIDER,
		RESEND_API_KEY: process.env.RESEND_API_KEY,
		AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
		AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
		AWS_REGION: process.env.AWS_REGION,
		ORM_TYPE: process.env.ORM_TYPE, // drizzle or prisma
		BREVO_API_KEY: process.env.BREVO_API_KEY,
		BREVO_SUBSCRIPTION_LIST_ID: process.env.BREVO_SUBSCRIPTION_LIST_ID,
		NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
		NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
		PLAN_1_DISCOUNT_CODE: process.env.PLAN_1_DISCOUNT_CODE,
		AUTH_TYPE: process.env.AUTH_TYPE,
		MEDIA_BUCKET_NAME: process.env.MEDIA_BUCKET_NAME,
		MEDIA_BUCKET_URL: process.env.MEDIA_BUCKET_URL,
		RESUME_MEDIA_BUCKET_NAME: process.env.RESUME_MEDIA_BUCKET_NAME,
		GPT_MESSAGE_QUEUE_URL: process.env.GPT_MESSAGE_QUEUE_URL,
		INDEX_MESSAGE_QUEUE_URL: process.env.INDEX_MESSAGE_QUEUE_URL,
		MONGO_DB_LOCAL_SEARCH: process.env.MONGO_DB_LOCAL_SEARCH,
		PLAN_1_PRICE_ID: process.env.PLAN_1_PRICE_ID,
	},
});

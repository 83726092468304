"use client";

import dynamic from "next/dynamic";

import { Env } from "@/lib/Env.mjs";

const isProd = process.env.NODE_ENV === "production";

const GoogleAnalytics = dynamic(() => import("./google-analytics"), {
  ssr: false,
});

export const Index = () => {
  if (!isProd) {
    return null;
  }

  if (isProd && Env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) {
    return <GoogleAnalytics />;
  }

  return null;
};

export default Index;
